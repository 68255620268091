@import "../../init";

.btn-container {
  text-align: center;
}

.btn {
  text-transform: none;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: center;
  color: white;
  background: #ffc700;
  border: 1px solid white;
  display: inline-block;
  cursor: pointer;
  max-width: 22rem;
  border-radius: 7px;
  padding: 1rem 2rem;
  font-weight: 700;

  &:hover {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }

  &--light {
    color: #ffc700;
    background: transparent;
    border: 1px solid #ffc700;

    &:hover {
      border: 1px solid #ffc700;
      opacity: 0.6;
      background: transparent;
    }
  }

  &--white {
    color: #ffc700;
    background: white;
    border: 1px solid #ffc700;
  }

  &--no-border {
    border: none;
  }

  &--popup {
    color: white;
    background: var(--blue);
    border: 1px solid white;
    min-width: fit-content;
  }

  &--sign_out {
    color: white;
    border: none;
  }
}
