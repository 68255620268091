@import '../../init';

body {
  font-family: 'Barlow', sans-serif;
}

.page-container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;

  > .column.left-container {
    flex: 0 0 37.5%;
    background-color: #f9f9f9;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .logo-container {
      position: absolute;
      top: 4rem;
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: space-around;

      @media (--screen-md) {
        top: 4rem;
      }

      @media (max-width: 845px) {
        top: 2rem;
      }

      @media (--screen-sm) {
        top: 2rem;
      }

      img {
        height: 100%;
        width: 50%;

        @media (--screen-xs) {
          height: 2rem;
          width: auto;
        }
      }
    }

    .content {
      padding: 0 4rem;
      overflow: scroll;

      @media (--screen-md) {
        padding: 0 2rem;
        margin-top: 6rem;
        height: calc(100vh - 6rem);
        display: flex;
        align-items: center;
      }

      h2 {
        font-family: 'Barlow', sans-serif;
        font-size: 1.5rem;
        line-height: 1.2em;
        margin: 0;
      }
    }

    .action-container {
      margin-top: 2rem;
    }

    .client-quote {
      h2 {
        font-size: 1rem;
      }

      background: white;
      padding: 1.4rem;
      box-sizing: border-box;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  > .column.right-container {
    flex: 0 0 63.5%;
    display: flex;
    align-items: center;
    position: relative;

    .back-link {
      position: absolute;
      top: 2.5rem;
      left: 2.5rem;
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;

      @media (--screen-md) {
        top: 4.5rem;
        left: 1.5rem;
      }

      @media (max-width: 845px) {
        top: 2.5rem;
      }

      @media (--screen-sm) {
        top: 2.5rem;
        left: 2.5rem;
      }

      @media (--screen-xs) {
        top: 4rem;
        left: 2.5rem;
      }

      img {
        width: 1.2rem;
        height: 1.2rem;
      }

      a {
        color: #727272;
        font-weight: 500;
        font-size: 1rem;
      }

      &:hover {
        opacity: 0.4;
      }
    }

    .form-container {
      width: 55%;
      padding: 2rem 0;
      margin: auto;

      @media (--screen-sm) {
        min-width: 50vw;
      }

      h2 {
        font-family: 'Barlow', sans-serif;
        font-size: 1.5rem;
        line-height: 1.2em;
      }
    }
  }
}

/* UTILITIES */
.blue {
  color: #1882ff;
}
