@import '../../init';

div.input {
  display: block;
  position: relative;
  width: auto;
  margin: 0 0 2rem 0;

  label {
    margin-bottom: 1rem;
    display: block;
    font-weight: 600;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 8px 0 8px 2px;
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: 'Barlow';
    background-color: transparent;
    color: #333;
  }

  span.required {
    display: none;
  }

  span.hint {
    font-size: 0.8rem;
    font-style: italic;
    margin-top: 0.8rem;
    display: block;
    color: #727272;
  }

  &.field_with_errors {
    > input {
      border-bottom: 1px solid #f39274;
    }

    > span.error {
      font-family: 'Fira Sans';
      font-weight: 900;
      font-size: 0.8rem;
      color: #f39274;
      margin-left: 0.4rem;
    }
  }
}

div.boolean {
  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    width: 0;
  }

  label.boolean {
    position: relative;
    padding-left: 2em;
    font-weight: 400;
    font-family: 'Barlow';
    color: #333;
  }

  label.boolean.strong {
    font-weight: bold;
  }

  label::before {
    cursor: pointer;
    content: "";
    height: calc(1em - 2px);
    width: calc(1em - 2px);
    border: 1px solid rgb(253, 225, 139);
    background-color: rgba(253, 225, 139, 0.2);
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    left: 0;
  }

  input:checked ~ label::after {
    content: "";
    height: calc(1em - 6px);
    width: calc(1em - 6px);
    border: 1px solid rgb(253, 225, 139);
    background-color: rgb(253, 225, 139);
    border-radius: 50%;
    display: inline-flex;
    margin-right: 1em;
    position: absolute;
    top: 2px;
    left: 2px;
  }

  .hint {
    margin-left: 2rem;
  }
}

div.boolean.disabled {
  label.boolean {
    color: rgb(155, 155, 155, 0.5);
  }

  label::before {
    cursor: not-allowed;
    border: 1px solid rgb(155, 155, 155, 0.5);
    background-color: white;
  }

  .hint {
    color: rgb(155, 155, 155, 0.5);
  }
}

.smart-actions {
  margin-bottom: 2rem;

  a {
    font-family: 'Barlow';
    font-weight: 400;
    font-style: italic;
    font-size: 0.9rem;
    color: #727272;

    &:hover {
      opacity: 0.6;
    }
  }
}

.form-actions {
  text-align: center;
}

.error-container {
  margin-bottom: 1rem;

  span {
    font-family: 'Fira Sans';
    font-weight: 900;
    font-size: 0.8rem;
    color: #f39274;
    margin-left: 0.4rem;
  }

  .notice,
  .alert {
    display: flex;
    align-items: center;
  }

  .notice {
    span {
      color: #2ccf46;
    }
  }
}
